<template>

    <div>
        <div v-if="loginToSchool">
            <br>
            <h4 class=" text-center">{{ schoolName }}</h4>
            <br>
        </div>

        <div class="col d-sm-flex justify-content-center">
            <div class="card card-login">
                <div class="card-body">
                    <form v-on:submit="login" class="">

                        <b-form-group id="schoolId" label="" label-for="schoolId" description="" v-if="schools.length > 0">
                            <b-form-select v-model="schoolId">
                                <option value="0">- Scegli la tua scuola -</option>
                                <option v-for="item in schools" v-bind:value="item.id" :key="item.id">
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>

                        <div class="form-group" v-if="schools.length == 0">
                            <input
                                type="text"
                                class="form-control"
                                id="email"
                                ref="email"
                                required
                                name="email"
                                v-model="email"
                                placeholder="Email"
                            />
                        </div>
                        <div class="form-group" v-if="schools.length == 0">
                            <input
                                :type="(passwordVisible) ? 'text' : 'password'"
                                class="form-control"
                                id="password"
                                required
                                name="password"
                                v-model="password"
                                placeholder="Password"
                            />
                            <b-icon icon="eye" class="toggle-password" v-if="!passwordVisible" v-on:click="togglePasswordView" />
                            <b-icon icon="eye-slash" class="toggle-password" v-if="passwordVisible" v-on:click="togglePasswordView" />
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success btn-block" type="submit">Entra</button>
                        </div>
                    </form>

                    <p class="text-center"><router-link to="/forgot-password" v-if="schools.length == 0">Password dimenticata</router-link></p>
                    <hr>
                    <p class="text-center text-muted" v-if="canRegister">
                        Oppure se non sei ancora registrato<br><br>
                        <router-link to="/register" class="btn btn-outline-primary" >Registrati</router-link>
                    </p>
                    <p class="text-center" v-if="!canRegister && schools.length == 0">
                        <router-link to="/activate-home" class="btn btn-warning">Attiva un nuovo prodotto</router-link>
                    </p>
                    <p class="text-danger text-center" v-if="errorMsg">{{ errorMsg }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AuthDataService from "./AuthDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import {HomepageByRole} from "@/helpers/HomepageByRole"
import {ParseUrl} from "@/helpers/ParseUrl"
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";

export default {
    name: "Login",
    data() {
        return {
            email: "",
            password: "",
            schoolId: null,
            schools: [],
            errorMsg: null,
            loginToSchool: false,
            addToSchool: false,
            canRegister: false,
            schoolName: '',
            redirectUri: '',
            parsedUrl: null,
            passwordVisible: false
        };
    },
    methods: {
        login(e) {
            e.preventDefault()
            let data = {
                email: this.email,
                password: this.password,
                schoolId: this.schoolId,
                addToSchool: this.addToSchool
            }
            AuthDataService.login(data)
                .then((response) => {
                    if (response.data.auth) {
                        if (response.data.schools) {
                            // l'utente appartiene a più scuole, bisogna scegliere la scuola
                            this.schools = response.data.schools;
                            this.schoolId = 0;
                        } else {
                            let authUser = {};
                            authUser.data = response.data.user;
                            authUser.token = response.data.token;
                            window.localStorage.setItem('authUser', JSON.stringify(authUser));
                            this.$store.state.user = authUser.data;
                            this.$store.state.token = authUser.token;
                            this.$store.state.schoolId = authUser.data.schoolId;
                            this.$store.state.schoolName = authUser.data.schoolName;
                            this.$store.state.schools = authUser.data.schools;
                            let self = this;
                            let uri = null;
                            if (response.data.user.privacy === false) uri = '/confirm-profile/' + response.data.token + '/1';
                            else if (this.redirectUri.length > 1) uri = this.redirectUri;
                            else uri = HomepageByRole(authUser.data.roles[0]);
                            this.$router.push(uri).then(response => {
                                self.$store.state.isLoggedIn = true;
                            }).catch(e => {
                            });
                        }
                    }
                    document.dispatchEvent(new Event('onLoggedIn'));
                })
                .catch((errors) => {
                    this.$store.state.loading = false;
                    switch (errors.response.data.errorCode) {
                        case 1005:
                            this.errorMsg = "Credenziali errate";
                            break;
                        case 1004:
                            this.errorMsg = "Permesso negato";
                            break;
                        case 1007:
                            this.errorMsg = "Non hai accesso a questa scuola";
                            break;
                        default:
                            this.errorMsg = errors.response.data.message;
                    }

                    console.log("Cannot log in:" + errors.response.data.errorCode);
                });
        },

        focusEmail() {
            this.$refs.email.focus();
        },
        togglePasswordView() {
            this.passwordVisible = !this.passwordVisible;
        }
    },
    mounted() {
        this.parsedUrl = ParseUrl();
        if (!this.$store.state.isLoggedIn) {
            this.schoolId = this.parsedUrl.schoolId;
            this.redirectUri = this.parsedUrl.redirectUri;

            if (this.parsedUrl.redirectUri.includes('activate')) {
                // get product_license info
                const licenseCode = this.parsedUrl.redirectUri.split('/')[2];
                if (licenseCode) ProductLicenseDataService.getSchoolIdByCode(licenseCode)
                    .then((response) => {
                        // add profile to school lincese belongs to a differente school
                        this.addToSchool = true;
                        // pass schoolId
                        this.schoolId = response.data.schoolId;
                        this.canRegister = true;
                        console.log(this.schoolId)
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }

            if (this.schoolId) { // se viene passato l'ID della scuola
                this.loginToSchool = true;
                this.canRegister = true;
                this.addToSchool = true;
                SchoolDataService.getInfo(this.schoolId)
                    .then((response) => {
                        this.schoolName = response.data.name;
                    })
                    .catch((errors) => {
                        this.schoolId = null;
                        this.loginToSchool = false;
                        this.errorMsg = 'QUESTA SCUOLA NON ESISTE'
                        console.log(errors);
                    });
            }
        }
    }
}
</script>

<style>
.toggle-password {
    cursor: pointer;
    position: relative;
    top: -30px;
    left: 90%;
}
</style>
